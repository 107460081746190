
@import "../../bootstrap/variables.less";

@body-bg:					#000;

@text-color:				#fff;
@link-color:				#fff;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			Arial, Helvetica, sans-serif;
@font-size-base:			18px;
@line-height-base:			1;

@font-size-h1:				18px;
@headings-small-color:		#fff;

@thumb-width:				492px;
@thumb-height:				368px;
@thumb-margin-top:			1px;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		1px;
@thumb-margin-left:			1px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #323630;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#fff;
@popup-text-color:			#000;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				15px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				2px;
@sites-footer-size:					12px;
@sites-footer-color:				#fff;

@parent-alert-padding:		10px;
@parent-alert-margin:		20px auto;
@parent-alert-border:		1px solid #000;
@parent-alert-font-size:	11px;
@parent-alert-color:		#fff;
@parent-alert-bg:			#0035d4;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	background-color: #0033ca;
	margin: 0 auto 3px;
	.title {
		color: #fff;
		font-weight: bold;
		margin: 0px 10px;
	}
	a {
		color: #fff;
		font-size: 14px;
	}
}
#language-switcher {
	float: right;
	margin: 0 10px;
	width: 50px;
	color: #000;
	background: #0033ca;
	border-radius: 100px;
	text-align: center;
}
.outro {
	font-size: 18px;
	text-align: center;
	a {
		text-decoration: none;
		color: #fff;
	}
}